( $ => {
  const mobileCloneItems = ( container ) => {
    if ( container.hasClass( 'mobile-items-clone' ) ) {
      return;
    }
    container.addClass( 'mobile-items-clone' );
    container.find( '.bs-div--home-vertical-slider-section' ).removeClass( 'desktop-items-clone' );
    $( '.desktop-cloned' ).remove();
    container.find( '> .bs-div__inner > .bs-div--home-vertical-slider-section' ).each( ( index, element ) => {
      for( let i = 0; i < 3; i++ ) {
        container.find( '> .bs-div__inner' )
          .append( $( element ).not( '.mobile-cloned' )
            .clone().addClass( 'mobile-cloned' ) );
      }
    } );
  };

  const desktopCloneItems = ( container ) => {
    if ( container.hasClass( 'desktop-items-clone' ) ) {
      return;
    }
    container.addClass( 'desktop-items-clone' );
    container.parents( '.mobile-items-clone' ).removeClass( 'mobile-items-clone' );
    $( '.mobile-cloned' ).remove();

    container.find( '.bs-div__inner' ).each( ( index, element ) => {
      for ( let i = 0; i < 3; i++ ) {
        $( element ).append( $( element )
          .find( '.media-elements:not(.desktop-cloned)' )
          .clone().addClass( 'desktop-cloned' ) );
      }
    } );
  };

  const logoAppend = () => {
    const isMobile = window.matchMedia( '(max-width: 991px)' ).matches;
    const imgSliderSection = $( '.bs-section--home-vertical-slider' );
    imgSliderSection.removeClass( 'start-marquee' );
	
    if ( isMobile ) {
      const containerSetOne = $( '.bs-div--home-vertical-slider-set-one' );
      const containerSetTwo = $( '.bs-div--home-vertical-slider-set-two' );
	
      mobileCloneItems( containerSetOne );
      mobileCloneItems( containerSetTwo );

      imgSliderSection.on( 'mouseover mouseleave', '.media-elements', function ( e ) {
        const targetElem = $( this ).find( 'a' );
        
        if ( e.type === 'mouseover' ) {
          targetElem.closest( '[class*=bs-div--home-vertical-slider-set-]' ).addClass( 'pause-marquee' );
        } else if ( e.type === 'mouseleave' ) {
          targetElem.closest( '[class*=bs-div--home-vertical-slider-set-]' ).removeClass( 'pause-marquee' );
        }
      } );
    } else {
      const containerSetOne = $( '.bs-div--home-vertical-slider-set-one .bs-div--home-vertical-slider-section' );
      const containerSetTwo = $( '.bs-div--home-vertical-slider-set-two .bs-div--home-vertical-slider-section' );
	
      desktopCloneItems( containerSetOne );
      desktopCloneItems( containerSetTwo );

      imgSliderSection.on( 'mouseover mouseleave', '.media-elements', function ( e ) {
        const targetElem = $( this ).find( 'a' );
        
        if ( e.type === 'mouseover' && targetElem.length > 0 ) {
          imgSliderSection.addClass( 'pause-marquee' );
        } else if ( e.type === 'mouseleave' && targetElem.length > 0 ) {
          imgSliderSection.removeClass( 'pause-marquee' );
        }
      } );
    }

    imgSliderSection.addClass( 'start-marquee' );
  };  

  $( window ).on( 'resize load', () => {
    logoAppend();
  } );
} )( jQuery );