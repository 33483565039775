$( ( $ ) => {
  const accordionCardsSection = $( '.bs-section--home-one-platform' );
  const accordionCards = $( '.bs-div--home-one-platform' );
  if ( accordionCards.length === 0 ) return;
  const changeWrapAll = () => {
    if ( window.matchMedia( '(max-width: 767.98px)' ).matches ) {
      if ( !accordionCardsSection.hasClass( 'mobile-active' ) ) {
        accordionCardsSection.addClass( 'mobile-active' );
        accordionCards.eq( 0 ).addClass( 'expanded' );
        accordionCards.each( ( i, accordionCard ) => {
          const $accordionCard = $( accordionCard );
          const head = $( '<div class="bs-blurb__head"></div>' );
          const body = $( '<div class="bs-blurb__body"></div>' );
          head
            .append( $accordionCard.find( '.wp-block-e25m-svg-animation-block' ) )
            .append( $accordionCard.find( '.bs-blurb__title' ) );
          body
            .append( $accordionCard.find( '.bs-blurb__sub-title' ) )
            .append( $accordionCard.find( '.bs-blurb__description' ) )
            .append( $accordionCard.find( '.bs-blurb__read-more' ) );
          $accordionCard.find( '.bs-blurb__container' )
            .append( head )
            .append( body );
          head.on( 'click', () => {
            if ( $accordionCard.hasClass( 'expanded' ) ) {
              body.slideUp( 600 );
              $accordionCard.removeClass( 'expanded' );
              return;
            }
            accordionCards.removeClass( 'expanded' ).find( '.bs-blurb__body' ).slideUp( 800 );
            body.slideDown( 800 );
            $accordionCard.addClass( 'expanded' );
          } );
        } );
      }
    } else {
      accordionCardsSection.removeClass( 'mobile-active' );
    }
  };
  $( window ).on( 'load resize', changeWrapAll );
} );
