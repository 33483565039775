$( () => {
  const section = $( '.bs-tab-slider--comprehensive-platform' );
  if ( section.length === 0 ) return;
  const unslickSlides = section.find( '.bs-slider-content .slick-slide-wrapper' );
  const tabSlider = section.find( '.bs-slider-tabs' );
  const contentSlider = section.find( '.bs-slider-content' );
  let rootMarginValue;
  if ( window.matchMedia( '(min-width: 1199px) and (max-width:1919px)' ).matches ) {
    rootMarginValue = '-35% 0px -65%';
  } else if ( window.matchMedia( '(min-width: 1920px) and (max-width: 3499px)' ).matches ) {
    rootMarginValue = '-25% 0px -75%';
  } else if ( window.matchMedia( '(min-width: 3500px)' ).matches ) {
    rootMarginValue = '-15% 0px -85%';
  } else {
    rootMarginValue = '-50% 0px';
  }
  const intersectionObserverOptions = {
    root: null,
    rootMargin: rootMarginValue,
    threshhold: 0.2,
  };
  let buttonClicked = false;
  //Observe each compoenent
  const slideObserverCallback = observe => {
    $( observe ).each( ( index, element ) => {
      const tabConent = $( element.target );
      if ( tabConent.hasClass( 'with-unlock-flag' ) ) {
        tabConent.removeClass( 'with-unlock-flag' );
        buttonClicked = false;
      }
      if ( element.isIntersecting && !buttonClicked ) {
        const currentDataId = tabConent.data( 'slick-index' );
        activeTab( currentDataId );
      }
    } );
  };
  const activeTab = tabIndex => {
    tabSlider.find( '.slick-slide-wrapper[data-slick-index]' ).removeClass( 'active' );
    tabSlider.find( `.slick-slide-wrapper[data-slick-index=${tabIndex}]` ).addClass( 'active' );
  };
  const updateActiveTabContent = tabIndex => {
    contentSlider
      .find( '.slick-slide-wrapper' )
      .removeClass( 'with-unlock-flag' )
      .filter( '.tab-active-content' )
      .removeClass( 'tab-active-content' );
    contentSlider.find( `[data-slick-index=${tabIndex}]` ).addClass( 'tab-active-content' );
  };
  tabSlider.on( 'click', event => {
    buttonClicked = true;
    const slideWrapper = $( event.target ).closest( '.slick-slide-wrapper' );
    const tabIndex = slideWrapper.data( 'slick-index' );
    const activeContentSlide = contentSlider.find( `[data-slick-index=${tabIndex}]` );
    activeTab( tabIndex );
    updateActiveTabContent( tabIndex );
    contentSlider.find( `[data-slick-index=${tabIndex}]` ).addClass( 'with-unlock-flag' );
    $( 'html,body' ).animate(
      {
        scrollTop: activeContentSlide.offset().top - 110,
      },
      0
    );
  } );
  //Passing values to I/O
  if ( unslickSlides.length ) {
    unslickSlides.each( ( index, slide ) => {
      const slideObserver = new IntersectionObserver( slideObserverCallback, intersectionObserverOptions );
      slideObserver.observe( slide );
    } );
  }
  activeTab( 0 );
  updateActiveTabContent( 0 );
} );
