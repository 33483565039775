$( $ => {
  const postImageDiv = $( '<div class="bs-post__image"></div>' );
  const featuredSinglePost = $( '.bs-single-post--home-featured-resource ' );
  const noImage = featuredSinglePost.hasClass( 'bs-post__container--no-image' );
  const postInner = featuredSinglePost.find( '.bs-post__inner' );

  if ( noImage ) {
    postInner.prepend( postImageDiv );
  }

} );