import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
require( 'slick-carousel-latest' );
gsap.registerPlugin( ScrollTrigger );
const slickGoToFunc = ( slider, clickedIndex ) => {
  const slideCount = slider.find( '.slick-slide' ).length;
  const slideToShowCount = slider.find( '.slick-active' ).length;
  const slickMoveCount = slideCount - slideToShowCount;
  const slickGoToIndex = ( clickedIndex <= slickMoveCount ) ? clickedIndex : slickMoveCount;
  $( slider ).slick( 'slickGoTo', slickGoToIndex );
};
const stickTabsInit = ( tabSliderClass, hasSlider, isVertical ) => {
  const stickyCardsSection = $( `.${tabSliderClass}` );
  const stickyCards = stickyCardsSection.find( '.bs-slider-content .slick-slide-wrapper' );
  const stickyTabSlider = stickyCardsSection.find( '.bs-slider-tabs' );
  const stickyTabs = stickyTabSlider.find( '.slick-slide-wrapper' );
  const clz = `.${tabSliderClass} .bs-slider-content .slick-slide-wrapper`;
  let isClicked = false;
  if( $( window ).width() < 991 || stickyCards.length === 0 ) {
    return;
  }

  const stickyCardsSectionHeight = stickyCardsSection.outerHeight();
  stickyCardsSection.attr( 'data-height', stickyCardsSectionHeight );

  const stickyCardsGsap = gsap.utils.toArray( clz );
  const stickPos = parseInt( stickyTabSlider.css( 'top' ) );
  const startingPos = ( isVertical ) ? stickPos : stickPos + stickyTabSlider.height() + 40;

  stickyCardsGsap.forEach( ( stickyCard, index ) => {
    $( stickyTabs ).eq( 0 ).addClass( 'tab-active' );
    const cardHeight = stickyCard.offsetHeight;
    const activateStickyCard = ( isClicked ) => {
      $( stickyCards ).removeClass( 'content-active' );
      $( stickyTabs ).removeClass( 'tab-active' );
      if ( !isClicked ) {
        $( stickyCard ).addClass( 'content-active' );
        $( stickyTabs ).eq( index ).addClass( 'tab-active' );
        if( hasSlider ) {
          slickGoToFunc( stickyTabSlider, index );
        }
      }
    };
    gsap.to( stickyCard, {
      transformOrigin: 'top center',
      duration: 1,
      scrollTrigger: {
        trigger: stickyCard,
        start: `top ${startingPos}px`,
        end: `+=${cardHeight}`,
        scrub: 0.8,
        onEnter: () => {
          activateStickyCard( isClicked );
        },
        onEnterBack: () => {
          activateStickyCard( isClicked );
        },
      },
    } );
  } );

  stickyTabs.on( 'click', ( e ) => {
    isClicked = true;
    const clickedIndex = Array.from( stickyTabs ).indexOf( e.currentTarget );
    const clickedCard = stickyCards.eq( clickedIndex );
    $( 'html' ).animate( {
      scrollTop: clickedCard.offset().top - ( startingPos - 10 )
    },100, function(){
      setTimeout( ()=> {
        $( stickyCards ).removeClass( 'content-active' );
        $( stickyTabs ).removeClass( 'tab-active' );
        $( stickyCards ).eq( clickedIndex ).addClass( 'active' );
        $( stickyTabs ).eq( clickedIndex ).addClass( 'tab-active' );
        if( hasSlider ) {
          slickGoToFunc( stickyTabSlider, clickedIndex );
        }
        isClicked = false;
      },100 );
    } );
  } );
};
export { stickTabsInit };