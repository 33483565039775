/* eslint-disable no-undef, no-unused-vars */
$( () => {
  /* eslint-disable no-undef */
  $( document ).on( 'keyup', '.facetwp-type-search .facetwp-search', ( e ) => {
    const inputValue = e.currentTarget.value;
    if ( inputValue.length > 0 && inputValue.length < 2 ) {
      FWP.is_refresh = true;
      setTimeout( () => {
        FWP.is_refresh = false;
      }, 150 );
    }
  } );
  /* eslint-enable */

  function cleanFacetResult() {
    const searchSelections = $( '.facetwp-selections li' );
    searchSelections.each( function ( i, el ) {
      const itemType = $( el ).attr( 'data-facet' );
      const itemValue = $( el ).find( '.facetwp-selection-value' ).text();

      if ( itemType == 'search' && itemValue.trim() == '' ) {
        $( '.facetwp-type-reset' ).hide();
        $( el ).hide();
      } else {
        $( '.facetwp-type-reset' ).show();
      }
    } );
  }

  const hideFiltersWhenEmpty = () => {
    const allFacetOptions = $( '.facetwp-type-fselect .fs-options' );
    if ( allFacetOptions.length > 0 ) {
      allFacetOptions.each( ( i, e ) => {
        const element = $( e );
        if ( element.find( 'div' ).length === 0 ) {
          element.closest( '.bs-column' ).hide();
        } else {
          element.closest( '.bs-column' ).show();
        }
      } );
    }
  };

  const hideCheckboxFiltersWhenEmpty = () => {
    const allFacetOptions = $( '.facetwp-type-checkboxes' );
    if ( allFacetOptions.length > 0 ) {
      allFacetOptions.each( ( i, e ) => {
        const element = $( e );
        if ( element.find( '.facetwp-checkbox' ).length === 0 ) {
          element.closest( '.bs-div' ).remove();
        }
      } );
    }
  };

  /* eslint-disable no-undef */
  $( document ).on( 'facetwp-refresh', function () {
    if ( FWP.soft_refresh === true ) {
      FWP.enable_scroll = true;
    } else {
      FWP.enable_scroll = false;
    }
  } );
  /* eslint-enable */

  $( document ).on( 'click', '.facetwp-page', ( e ) => {
    const adminBarHeight =
      $( '#wpadminbar' ).length > 0 ? $( '#wpadminbar' ).height() : 0;
    const headerHeight =
      $( 'header' ).height() + adminBarHeight - 20;
    $( 'html, body' ).animate(
      { scrollTop: $( e.currentTarget ).closest( 'section' ).offset().top - headerHeight },
      1
    );
  } );

  /* eslint-disable no-undef */
  $( document ).on( 'facetwp-refresh', () => {
    if ( null !== FWP.active_facet ) {
      const facet = FWP.active_facet;
      const facet_name = facet.attr( 'data-name' );
      $( '.facetwp-facet[data-name="' + facet_name + '"]' ).addClass( 'current-loading-facetwp' );
    }
  } );
  /* eslint-enable */

  $( document ).on( 'facetwp-refresh', () => {
    $( '.facetwp-template' ).addClass( 'facetwp-template--loading' );
  } );

  $( document ).on( 'facetwp-loaded', () => {
    setTimeout( () => {
      cleanFacetResult();
    }, 0 );
    hideFiltersWhenEmpty();
    hideCheckboxFiltersWhenEmpty();
    $( '.facetwp-template' ).removeClass( 'facetwp-template--loading' );
    $( '.facetwp-facet' ).removeClass( 'current-loading-facetwp' );
  } );

  cleanFacetResult();

  $( window ).on( 'load resize', function () {
    hideFiltersWhenEmpty();
    hideCheckboxFiltersWhenEmpty();
  } );

  hideFiltersWhenEmpty();
  hideCheckboxFiltersWhenEmpty();
} );
