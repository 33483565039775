$( $ => {
  const header = $( 'header' );
  const headerMainSubmenu = header.find( '#mega-menu-main-menu' );
  const headerMainLink = header.find( '#mega-menu-main-menu > li.mega-menu-item.mega-menu-item-has-children' );

  //Add Class on Scroll
  const scrollClass = () => {
    if ( $( window ).scrollTop() >= 1 ) {
      header.addClass( 'page-scrolled' ).removeClass( 'page-at-top' );
    } else {
      header.removeClass( 'page-scrolled' ).addClass( 'page-at-top' );
    }
  };

  $( window ).bind( 'load scroll', () => {
    scrollClass();
  } );

  // SVG Animation
  const navigation = $( '#mega-menu-main-menu > .mega-menu-item > .mega-sub-menu' );
  const appendSvgToDropdown = () => {
    const objectTag = `<object type="image/svg+xml" 
  data="/wp-content/themes/berg-theme-child/dist/images/dropdown-animation-programmed.svg" 
  class="header-svg-animation"></object>`;

    navigation.append( objectTag );
  };

  appendSvgToDropdown();

  headerMainLink.on( 'mouseenter', ( e ) => {
    header.addClass( 'show-border' );
    headerMainSubmenu.addClass( 'mega-menu-open-hover' );
    const targetEl = e.target;
    const parentLink = $( targetEl ).closest( '.mega-menu-item-has-children' );
    const animatingSVG = parentLink.find( '.header-svg-animation' );
    if ( animatingSVG && !parentLink.hasClass( 'avg-animated' ) ) {
      parentLink.addClass( 'svg-animated' );
      const svgID = $( animatingSVG ).contents().find( 'svg' ).attr( 'id' );
      let svg = animatingSVG.get( 0 ).contentDocument.getElementById( svgID );
      svg.svgatorPlayer.ready( ( player ) => {
        player.play();
      } );
    }
  } );
  headerMainLink.on( 'mouseleave', ( e ) => {
    header.removeClass( 'show-border' );
    headerMainSubmenu.removeClass( 'mega-menu-open-hover' );
    const targetEl = e.target;
    const parentLink = $( targetEl ).closest( '.mega-menu-item-has-children' );
    parentLink.removeClass( 'svg-animated' );
  } );
} );
