$( ( $ ) => {
  if( $( '.bs-section--common-cta-form-fields' ).length > 0 ) {
    // eslint-disable-next-line no-undef
    MktoForms2.whenReady( () => {
      const form = $( '.mktoForm' );
      const formField = form.find( '.mktoFieldWrap' );
      const formLabel = form.find( '.mktoLabel' );
      
      formField.each( ( index, element ) => {
        let isRequired = $( element ).hasClass( 'mktoRequiredField' ) ? '*' : '';
        const labelText = $( element ).find( formLabel ).text().trim().replace( '*', '' ).replace( ':', isRequired );
        
        $( element ).find( '.mktoField:not(select)' ).attr( 'placeholder', labelText );
      } );
    } );
  }
  if( $( '.bs-div--common-form-wrapper' ).length > 0 ) {
    // eslint-disable-next-line no-undef
    MktoForms2.whenReady( () => {
      const form = $( '.mktoForm' );
      const formField = form.find( '.mktoFieldWrap' );

      // Onchange event handler
      formField.on( 'change', 'input, textarea', ( event ) => {
        const currentField = $( event.currentTarget );
        const fieldValue = currentField.val().trim();
        
        if ( fieldValue === '' ) {
          currentField.closest( '.mktoFieldWrap' ).removeClass( 'focused' );
        } else {
          currentField.closest( '.mktoFieldWrap' ).addClass( 'focused' );
        }
      } );
      
      // Blur event handler
      formField.on( 'blur', 'input, textarea', ( event ) => {
        const currentField = $( event.currentTarget );
        const fieldValue = currentField.val().trim();
        
        if ( fieldValue === '' ) {
          currentField.closest( '.mktoFieldWrap' ).removeClass( 'focused' );
        }
      } );
      
      // Focus event handler
      formField.on( 'focus', 'input, textarea', ( event ) => {
        const currentField = $( event.currentTarget );
        const clickedField = currentField.closest( '.mktoFieldWrap' );
        clickedField.addClass( 'focused' );
      } );
    } );
  }
} );