$( $ => {
  const counterContainer = $( '.bs-div--counter-banner' );
  const bannerCounterColumn = $( '.bs-column--home-banner' );
  const logoSliderColumn = $( '.bs-section--home-logo-slider' ).find( '.bs-row > .bs-column:last-of-type' );
  $( window ).on( 'load resize', () => {
    if ( counterContainer.length !== 0 && logoSliderColumn.length !== 0 ) {
      appendDetachCounters();
    }
    else {
      return;
    }
  } );
  const appendDetachCounters = () => {
    if ( !window.matchMedia( '(max-width: 991.98px)' ).matches ) {
      if ( logoSliderColumn.find( '.bs-div--counter-banner' ).length > 0 ) {
        const counterBanner = logoSliderColumn.find( '.bs-div--counter-banner' );
        counterBanner.detach();
        bannerCounterColumn.append( counterBanner );
      }
    } else {
      if( bannerCounterColumn.find( '.bs-div--counter-banner' ).length > 0 ) {
        const counterBanner = bannerCounterColumn.find( '.bs-div--counter-banner' );
        counterBanner.detach();
        logoSliderColumn.append( counterBanner );
      }
    }
  };
} );
