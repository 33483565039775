$( $ => {
  const fadeOutDuration = 1000;
  const fadeInDuration = 1000;
  const images = $( '.bs-div--home-banner-media .media-elements' );
  if ( images.length <= 1 ) {
    return;
  }

  const showNextImage = () => {
    const visibleImage = images.filter( ':visible' );
    const nextImage = visibleImage.next( '.media-elements' ).length
      ? visibleImage.next( ' .media-elements ' )
      : images.first();
    visibleImage.fadeOut( fadeOutDuration );
    nextImage.fadeIn( fadeInDuration );
  };
  const imageChangeInterval = 8000;
  setInterval( showNextImage, imageChangeInterval );
} );
