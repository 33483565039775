$( ( $ ) => {
  const duration = 600;
  const filterDiv = $( '.bs-column--sidebar-filter .bs-div' );
  const filterP = filterDiv.find( 'p' );

  if ( filterDiv.length == 0 ) {
    return;
  }
  filterDiv.find( '.facetwp-type-checkboxes' ).first().slideDown( duration )
    .parents( '.bs-div---default' ).addClass( 'bs-div--active' );
  filterP.on( 'click', ( e ) => {
    const $this = $( e.target );
    const div = $this.parents( '.bs-div---default' );
    const isExpanded = div.hasClass( 'bs-div--active' );
    if ( isExpanded ) {
      div.removeClass( 'bs-div--active' );
      div.find( '.facetwp-type-checkboxes' ).slideUp( duration );
    } else {
      div.find( '.facetwp-type-checkboxes' ).slideDown( duration );
      div.addClass( 'bs-div--active' );
    }
  } );
} );
